export const parseDate = (time, formate = "YYYY-MM-DD HH:mm:ss") => {
  if (!time) {
    return "";
  }
  let date = new Date(time),
    year = date.getFullYear(),
    month = returnZero(date.getMonth() + 1),
    day = returnZero(date.getDate()),
    hour = returnZero(date.getHours()),
    min = returnZero(date.getMinutes()),
    second = returnZero(date.getSeconds());
  if (formate == "YYYY") {
    return year;
  } else if (formate == "YYYY-MM") {
    return `${year}-${month}`;
  } else if (formate == "YYYY-MM-DD") {
    return `${year}-${month}-${day}`;
  } else if (formate == "YYYY-MM-DD HH") {
    return `${year}-${month}-${day} ${hour}:00`;
  } else if (formate == "YYYY-MM-DD HH:mm") {
    return `${year}-${month}-${day} ${hour}:${min}`;
  } else if (formate == "YYYY-MM-DD HH:mm:ss") {
    return `${year}-${month}-${day} ${hour}:${min}:${second}`;
  }
};

export const returnZero = (date) => {
  //时间返回加0
  return date < 10 ? "0" + date : date;
};
