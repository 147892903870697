<template>
  <div style="box">
    <router-view v-if="isRouteAlive" />
  </div>
</template>
<script setup>
import { nextTick, provide, ref } from "vue";
const isRouteAlive = ref(true);
// 通过app组件来控制页面刷新
const reload = () => {
  isRouteAlive.value = !isRouteAlive.value;
  nextTick(() => {
    isRouteAlive.value = true;
  });
};
provide("reload", reload);
</script>
<style lang="scss">
.box {
  width: 100vw;
  height: 100vh;
  // 将整个网站需要设置成黑白的
  // filter: progid:DXImageTransform.Microsoft.BasicImage(graysale=1);
  // -webkit-filter: grayscale(100%);
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

/**修改全局的滚动条*/
/**滚动条的宽度*/
::-webkit-scrollbar {
  width: 8px; //y轴宽度
  height: 8px; //x轴高度

}

//滚动条的滑块
::-webkit-scrollbar-thumb {
  background-color: #605ca8;
  border-radius: 3px;
}

.el-scrollbar__thumb {
  background-color: #605ca8 !important;
  opacity:1 !important;
}


//修改表格的滚动条
.el-table__body-wrapper::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

//滚动条的滑块
.el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: #605ca8;
  border-radius: 3px;
}
// input 框 图标鼠标
.el-input__suffix{
  cursor: pointer;
}
// #nprogress .bar {
//   background: red !important; //自定义颜色
// }
</style>
