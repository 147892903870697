//引入
import requests from "@/request/http.js"; //路劲自己修改
import { Url } from "@/utils/requestUrl";
// 登录
export const exit = (params) => {
  return requests({
    url: Url + "/user/logout",
    method: "post",
    data: params,
  });
};
