const webRouter = [
  {
    path: "/",
    name: "webHome",
    component: () => import("@/views/web/web-home/index.vue"),
    meta: { keepAlive: false, title: "首页" }, // true：需要被缓存
  },
  {
    path: "/company-profile",
    name: "companyProfile",
    component: () => import("@/views/web/company-profile/index.vue"),
    meta: { keepAlive: false, title: "公司简介" }, // true：需要被缓存
  },
  {
    path: "/core-business",
    name: "coreBusiness",
    component: () => import("@/views/web/core-business/index.vue"),
    meta: { keepAlive: false, title: "核心业务" }, // true：需要被缓存
  },
  {
    path: "/company-dynamic",
    name: "companyDynamic",
    component: () => import("@/views/web/company-dynamic/index.vue"),
    meta: { keepAlive: false, title: "公司动态" }, // true：需要被缓存
  },
  {
    path: "/laws-regulations",
    name: "lawsRegulations",
    component: () => import("@/views/web/laws-regulations/index.vue"),
    meta: { keepAlive: false, title: "政策法规" }, // true：需要被缓存
  },
  {
    path: "/site-map",
    name: "siteMap",
    component: () => import("@/views/web/site-map/index.vue"),
    meta: { keepAlive: false, title: "网站导航" }, // true：需要被缓存
  },
  {
    path: "/contact-us",
    name: "contactUs",
    component: () => import("@/views/web/contact-us/index.vue"),
    meta: { keepAlive: false, title: "联系我们" }, // true：需要被缓存
  }
];

const pageRouter = [
  {
    path: "/other/portal/:id",
    name: "otherPortal",
    component: () => import("@/views/web/other/portal/index.vue"),
    meta: { keepAlive: false, title: "信用服务" }, // true：需要被缓存
  },
  {
    path: "/other/news/:id",
    name: "otherNews",
    component: () => import("@/views/web/other/news/index.vue"),
    meta: { keepAlive: false, title: "公司动态" }, // true：需要被缓存
  },
  {
    path: "/other/industry",
    name: "otherIndustry",
    component: () => import("@/views/web/other/industry/index.vue"),
    meta: { keepAlive: false, title: "行业动态" }, // true：需要被缓存
  },
];
export { webRouter, pageRouter };
