import router from "@/router";
import { toRaw } from "vue";
import { ElMessage } from "element-plus";
//请求接口
import { exit } from "./http.js";
const User = {
  namespaced: true,
  state: {
    userInfo: JSON.parse(sessionStorage.getItem("info")) || {},
    token:sessionStorage.getItem('token') ||"",
  },
  getters: {},
  mutations: {
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo;
      //存储token值
      state.token = userInfo.userName;
    },
    // 设置token
    setToken(state, data) {
      //存储token值
      state.token = data;
      sessionStorage.setItem('token',data)
    },
  },
  actions: {
    // 设置信息
    setUserAction(context, userInfo) {
      let obj = toRaw(userInfo);
      context.commit("setUserInfo", obj);
      //sessionStorage 只能存储字符串的属性值
      sessionStorage.setItem("info", JSON.stringify(obj));
      //跳转首页
      router.push("/home");
    },
    // 清空信息
    clearUserAction(context) {
      // 登录接口
      exit()
        .then((result) => {
          if (result.code == 10000) {
            context.commit("setToken", '');
            sessionStorage.clear();
            context.commit("setUserInfo", {});
            //跳转登录页面
            router.push("/login");
          }
        })
        .catch((err) => {
          ElMessage.error(err.message);
        });
    },
  },
  modules: {},
};
export default User;
