import { createStore } from "vuex";
import User from "./modules/user/index.js";
import Active from "./modules/active/index.js";
export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    User,
    Active,
  },
});
