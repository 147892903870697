import { createRouter, createWebHistory } from "vue-router";
import { webRouter,pageRouter } from "./web-router.js";
const routes = [
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/404/index.vue"),
    meta: { keepAlive: false, title: "页面404" }, // true：需要被缓存
  },
  {
    path: "/firm-grade",
    name: "firmGrade",
    component: () => import("@/views/firm-grade/index.vue"),
    meta: { keepAlive: false, title: "企业信用核查" }, // true：需要被缓存
  },
  {
    path: "/firm-details",
    name: "firmDetails",
    component: () => import("@/views/firm-details/index.vue"),
    meta: { keepAlive: false, title: "企业报告详情" }, // true：需要被缓存
  },
  {
    path: "/",
    name: "pages",
    component: () => import("@/views/web/index.vue"),
    redirect: "/",
    children: [...webRouter,...pageRouter]
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
