import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// 顶部滚动条的样式
import 'nprogress/nprogress.css'
// 数字叠加效果
import AnimatedNumber from 'animated-number-vue3'
// 引入第三方组件库
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
// 路由拦截器
import "@/router/promission";
// 定义日期全局方法
import { parseDate } from "@/utils/date.js";
import 'default-passive-events';
// 引入iconfot
import SvgIcon from '@/components/SvgIcon/index.vue'
import '@/assets/iconfont/iconfont.js';

//ResizeObserver loop limit exceeded 报错解决方案
const debounce = (fn, delay) => {
    let timer = null;
    return function () {
        let context = this;
        let args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
            fn.apply(context, args);
        }, delay);
    };
};
const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
    constructor(callback) {
        callback = debounce(callback, 16);
        super(callback);
    }
};

const app = createApp(App);
app.component('SvgIcon', SvgIcon);
app.use(ElementPlus).use(AnimatedNumber).use(store).use(router).mount("#app");
app.config.globalProperties.$parseDate = parseDate;