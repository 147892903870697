// axios 封装
// 请求拦截 响应拦截，错误统一处理
import router from "@/router";
import store from "@/store";
import axios from "axios"; // 引入axios
import { ElMessage } from "element-plus";
//导入进度条插件
import NProgress from 'nprogress'
//全局进度条的配置
NProgress.configure({ showSpinner: false })
const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // 设置请求的基本URL
  timeout: 30000, // 设置请求超时时间
  headers: {
    "Content-Type": "application/json", // 设置请求头部
  },
});
// 请求拦截器
instance.interceptors.request.use(
  (config) => {
    // 在请求发送之前做一些处理，如加入token等
    config.headers.token = store.state.User.token;
    NProgress.start();
    // 将配置完成的config对象返回出来，如果不返回，请求将不会进行
    return config;
  },
  (error) => {
     NProgress.done();
    // 请求发生错误时的相关处理，抛出错误
    return Promise.reject(new Error(error));
  }
);

// 响应拦截器
instance.interceptors.response.use(
  (response) => {
    // 在响应数据之前做一些处理
    if (response.status === 200 && response.data.code===200) {
      NProgress.done()
      return response.data; // 进行中
    } else {
      NProgress.done()
      //对错误信息的处理函数
      switch (response.data.code) {
        case 10003:
          //跳转登录页面
          router.push("/login");
          sessionStorage.clear();
          return ElMessage.error("未授权，请重新登录!");
        case 403:
          return ElMessage.error("拒绝访问");
        case 404:
          router.push("/404");
          return ElMessage.error("很抱歉，资源未找到!");
        case 500:
          return ElMessage.error(response.data.msg);
        case 504:
          return ElMessage.error("网络超时!");
        default:
          return ElMessage.error(response.data.msg);
      }
    }
  },
  // 服务状态不是200 的时候
  (error) => {
    NProgress.done();
    return Promise.reject(error);
  }
);
export default instance;
