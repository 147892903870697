import router from "./index.js";
import store from "@/store/index.js";
// 白名单
const whileList = ["/login", "/404"];
router.beforeEach((to, form, next) => {
  // 路由切换，将滚动条始终置顶
  // chrome
  document.body.scrollTop = 0
  // firefox 
  document.documentElement.scrollTop = 0
  sessionStorage.setItem("path", to.path);
  // 存储到vuex中
  store.commit("Active/setDefaultActive", to.path);
  // let userinfo = sessionStorage.getItem("info");
  // //如果进入到的路由是登录页或者注册页面，则正常展示
  // if (userinfo == null) {
  //   // 白名单是否拥有
  //   if (whileList.includes(to.path)) {
  //     next();
  //   } else {
  //     next("/login"); //转入login登录页面，
  //   }
  // } else {
  next();
  // }
});
