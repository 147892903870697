import router from "@/router";

const Active = {
  namespaced: true,
  state: {
    iconFlag: false,
    tabList: [],
    defaultActive: null || sessionStorage.getItem("path"),
  },
  getters: {},
  mutations: {
    // 左侧菜单栏的回显
    setICon(state, flag) {
      state.iconFlag = flag;
    },
    // 真个菜单栏的状态
    setDefaultActive(state, path) {
      state.defaultActive = path;
    },
    // 选择tab
    setTab(state, tabs) {
      if (tabs.path == "/home") {
        sessionStorage.setItem("path", tabs.path);
        return;
      }
      const targetObject = state.tabList.find((obj) => obj.path === tabs.path);
      if (targetObject == undefined) {
        state.tabList.push(tabs);
        sessionStorage.setItem("tabList", JSON.stringify(state.tabList));
        sessionStorage.setItem("path", tabs.path);
      }
    },
    // 删除tab
    removeTab(state, paneName) {
      state.tabList.forEach((element, index) => {
        if (element.path == paneName) {
          state.tabList.splice(index, 1);
          // 当前删除的tab 跟存储的tab一样 状态发生变化，如果不一样，只让tab删除就可以了
          if (paneName == state.defaultActive) {
            let path =
              state.tabList[index] == undefined
                ? state.tabList[index - 1] == undefined
                  ? "/home"
                  : state.tabList[index - 1].path
                : state.tabList[index].path;
            sessionStorage.setItem("path", path);
            state.defaultActive = path;
            router.push(path);
          }
        }
      });
      sessionStorage.setItem("tabList", JSON.stringify(state.tabList));
    },
    // 页面刷新后的 tab数据
    setTabList(state, tabList) {
      state.tabList = tabList;
      sessionStorage.setItem("tabList", JSON.stringify(state.tabList));
    },
  },
  actions: {
    // 设置菜单栏状态
    setIConFlag(context, flag) {
      context.commit("setICon", flag);
    },
  },

  modules: {},
};
export default Active;
